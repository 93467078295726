import React, { useState,useEffect } from "react";

import { Box, IconButton, LinearProgress, Button, Dialog, DialogActions, DialogTitle,DialogContent,Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import useSWR from "swr";
import { useSnackbar } from "notistack";

import { appFetch, swrFetcher } from "../utils/fetch";
import AppFAB from "../components/AppFAB";
import CreateCardDialog from "../components/dialogs/CreateCardDialog";
import { useConfirm } from "material-ui-confirm";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import ConnectCardToUserDialog from "../components/dialogs/ConnectCardToUserDialog";
import { dataGridSx } from "../plugins/mui";

const CardsPage = () => {
  const [isOpenCreateCardDialog, setIsOpenCreateCardDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isOpenConnectUserToCardDialog, setIsOpenConnectUserToCardDialog] =
    useState(false);
    const [balanceStates, setBalanceStates] = useState({});
    const [transactionsStates, setTransactionsStates] = useState({});
    const [flatTransactionsStates, setFlatTransactionsStates] = useState({});
    const [loadsStates, setLoadsStates] = useState({});
    const [flatLoadsStates, setFlatLoadsStates] = useState({});
    const [allBalances, setAllBalances] = useState(null);
  const closeConnectUserToCardDialog = () =>
    setIsOpenConnectUserToCardDialog(false);
  const openConnectUserToCardDialog = () =>
    setIsOpenConnectUserToCardDialog(true);
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [open, setOpen] = useState(false); // מצב לפתיחת דיאלוג

  const closeCreateCardDialog = () => setIsOpenCreateCardDialog(false);
  const openCreateCardDialog = () => setIsOpenCreateCardDialog(true);
  const [isOpenActionDialog, setIsOpenActionDialog] = useState(false); // מצב לדיאלוג בחירת הפעולה
  const openActionDialog = () => setIsOpenActionDialog(true);
  const closeActionDialog = () => setIsOpenActionDialog(false);

  const baseUrl = "/api/card";
  const [loading, setLoading] = useState(false);

  const { data, mutate } = useSWR(baseUrl, swrFetcher);//הבאת כל הכרטיסים ממונגו
  const { data: upayData, error } = useSWR(
    "/api/upayOrganizations", // נתיב ה-API
    swrFetcher,               // הפונקציה שמבצעת את הקריאה
    {
      revalidateOnFocus: false,  // אין קריאה מחדש כשמתמקדים בדף
      revalidateIfStale: false,  // אין רענון אם הנתונים לא התיישנו
      revalidateOnReconnect: false, // אין קריאה מחדש אחרי התחברות מחדש
    }
  );
  
  //const { data: allCardsBalance } = useSWR("/api/card/getCardsBalance", swrFetcher);
  console.log(upayData);

  useEffect(() => {
    // המתנה עד שהבקשה מסתיימת
    if (upayData !== undefined) {
      try {
        if (!Array.isArray(upayData)) {
          throw new Error("upayData אינו מערך או אינו מוגדר");
        }

      } catch (error) {
        console.error("שגיאה בפריסת המערך:", error.message);
        alert("אירעה שגיאה בקבלת נתונים מUPAY. אנא נסה שוב מאוחר יותר.");
      }
    }
  }, [upayData]);
  console.log(data);
  //console.log(allCardsBalance);
  

  const upayCardsFromExcel = async (upayData) => {//הוספת כרטיסים מUPAY למונגו
    try {
      setLoading(true);
      const issueDate = new Date().toLocaleDateString('en-US');
      const res = await appFetch(`/api/card/addAllUpayCardFromExcel`, {
        method: "POST",
        body: JSON.stringify({ ...upayData, issueDate }),
      });
      if (res.ok){
        console.log("cards added")
      }
      if (!res.ok) throw new Error();
    } catch (err) {
      console.error(err);
      enqueueSnackbar("אירעה שגיאה", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const upayCardsFromZoho = async (upayData) => {//הוספת כרטיסים מUPAY למונגו
    try {
      setLoading(true);
      const issueDate = new Date().toLocaleDateString('en-US');
      const res = await appFetch(`/api/card/addAllUpayCardFromZoho`, {
        method: "POST",
        body: JSON.stringify({ ...upayData, issueDate }),
      });
      if (res.ok){
        console.log("cards added")
      }
      if (!res.ok) throw new Error();
    } catch (err) {
      console.error(err);
      enqueueSnackbar("אירעה שגיאה", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const getAllCardsBalance = async () => {
    try {
      setLoading(true);
      const response = await appFetch(`/api/card/getCardsBalance`, {
        method: "GET",
      });
  
      if (!response.ok) throw new Error("Failed to fetch all card balances");
  
      let balancesData = await response.json();
  
      if (!balancesData.success) {
        enqueueSnackbar("נכשלה הבקשה לבדוק יתרות הכרטיסים", { variant: "error" });
        return;
      }
  
      const { successful, failed } = balancesData.results;
  
      // הודעה על בסיס תוצאות הכרטיסים
      let message = "הגיעו נתונים עבור הכרטיסים.";
      if (failed.length > 0) {
        message += ` אך לא הגיעו נתונים עבור ${failed.length} כרטיסים.`;
      } else {
        message += " הגיעו נתונים עבור כל הכרטיסים.";
      }
  
      // בדיקה של ErrorTypeNumber בכל הכרטיסים המוצלחים
      const successCount = successful.filter(
        (card) =>
          card.balanceData &&
          Array.isArray(card.balanceData.ErrorTypeNumber) &&
          card.balanceData.ErrorTypeNumber[0] === "-1"
      ).length;
  
      const totalSuccessful = successful.length;
      const partialSuccess = successCount !== totalSuccessful;
  
      if (partialSuccess) {
        const failedCount = totalSuccessful - successCount;
        message += ` יתרות ${successCount} כרטיסים נבדקו בהצלחה, אך היו בעיות בבדיקת יתרות עבור ${failedCount} כרטיסים.`;
      } else {
        message += " יתרות כל הכרטיסים נבדקו בהצלחה.";
      }
  
      enqueueSnackbar(message, { variant: "success" });
  
      // עדכון ה-state בהתאם
      const balancesMap = upayData.reduce((acc, { combineCardIdentifier }) => {
        const matchedCard = successful.find(
          (card) => card.cardIdentifier === combineCardIdentifier
        );
  
        if (matchedCard) {
          const { balanceData } = matchedCard;
          const amount =
            balanceData &&
            Array.isArray(balanceData.Amount) &&
            balanceData.Amount[0] !== undefined
              ? balanceData.Amount[0]
              : null;
  
          let error =
            balanceData &&
            Array.isArray(balanceData.ErrorTypeNumber) &&
            balanceData.ErrorTypeNumber[0] !== undefined
              ? balanceData.ErrorTypeNumber[0]
              : null;
  
          error = error === 0 ? "תקין" : "שגיאה";
  
          acc[combineCardIdentifier] = {
            balance: amount,
            error,
          };
        }
        return acc;
      }, {});
  
      setBalanceStates((prev) => ({
        ...prev,
        ...balancesMap,
      }));
  
      setAllBalances(balancesData);
    } catch (error) {
      console.error("Error fetching all card balances:", error);
      enqueueSnackbar("נכשלה הבקשה לבדוק יתרות הכרטיסים", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };
  

  const getBalance = async (row) => {
  try {
    const { CardCid, CardLast4Numbers, CustomerIdentifier, organization } = row;
    const response = await appFetch(`/api/card/getCardBalance`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ CardCid, CardLast4Numbers, CustomerIdentifier, organization }),
    });

    if (!response.ok) throw new Error("Failed to fetch balance");

    const data = await response.json();
    let balance = data.data.balance; // תמיד מחזירים את היתרה הקיימת
    let isError = !data.success; // אם success = false => יש שגיאה

    // עדכון הסטייט עם היתרה וסטטוס השגיאה
    setBalanceStates((prev) => ({
      ...prev,
      [row._id]: { balance, isError },
    }));

  } catch (error) {
    console.error("Error fetching balance:", error);
  }
};


  const transactionsColumns = [
    { field: "id", headerName: "מזהה עסקה", width: 150 },
    { field: "amount", headerName: "סכום", width: 100 },
    { field: "date", headerName: "תאריך", width: 150 },
    { field: "customerName", headerName: "שם לקוח", width: 150 },
    { field: "merchantName", headerName: "שם בית העסק", width: 250 },
    { field: "transactionType", headerName: "סוג עסקה", width: 150 },
  ];
  
  
  
  const getTransactions = async (row) => {
    try {
      const { CardCid, CardLast4Numbers, CustomerIdentifier } = row;
      const response = await appFetch(`/api/card/getTransactions`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ CardCid, CardLast4Numbers, CustomerIdentifier }),
      });
  
      if (!response.ok) throw new Error("Failed to fetch transactions");
  
      const data = await response.json();
      const transactions = data;
      console.log(transactions);
      setTransactionsStates(transactions); // שמירת העסקאות במצב
      // יצירת מערך שטוח של כל העסקאות
      const flatTransactions = data.reduce((acc, item, index) => {
        const transactions = item.CardTransaction || []; // מערך העסקאות בתוך כל תוצאה
        const flattenedTransactions = transactions.map((transaction, i) => ({
          id: `${index}-${i}`, // מזהה ייחודי לכל עסקה
          amount: transaction.OrigAmount,
          date: transaction.DateBuy? transaction.DateBuy.slice(0, 10): "",
          customerName: transaction.CustomerName,
          merchantName: transaction.MerchantName,
          transactionType: transaction.TranTypeDescription,
        }));
        return [...acc, ...flattenedTransactions];
      }, []);
  
      // 3. שמירת כל העסקאות השטוחות בסטייט `setFlatTransactionsStates`
      setFlatTransactionsStates((prev) => ({
        ...prev,
        [row.id]: flatTransactions,
      }));
  
      console.log(flatTransactions);
      setOpen(true); // פתיחת הדיאלוג לאחר קבלת הנתונים
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };

  const getAllCardsLoads = async (row) => {
    try {
      //const { CardCid, CardLast4Numbers, CustomerIdentifier } = row;
      const response = await appFetch(`/api/upayOrganizations/getLoads`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(),
      });
  
      if (!response.ok) throw new Error("Failed to fetch loads");
  
      const data = await response.json();
      const loads = data;
      console.log(loads);
      setLoadsStates(loads); // שמירת העסקאות במצב
      // יצירת מערך שטוח של כל העסקאות
      const flatLoads = data.reduce((acc, item, index) => {
        const loads = item.CardLoad || []; // מערך העסקאות בתוך כל תוצאה
        const flattenedLoads = loads.map((load, i) => ({
          id: `${index}-${i}`, // מזהה ייחודי לכל עסקה
          amount: load.AmountInCardCurrency?.[0],
          date: load.WhenRequested?.[0]? load.WhenRequested[0].slice(0, 10): "",
          customerName: load.CustomerName?.[0],
          transactionNumber: load.TransactionNumber?.[0],
        }));
        return [...acc, ...flattenedLoads];
      }, []);
  
      // 3. שמירת כל העסקאות השטוחות בסטייט `setFlatTransactionsStates`
      // setFlatLoadsStates((prev) => ({
      //   ...prev,
      //   [row.id]: flatLoads,
      // }));
  
      console.log(flatLoads);
      //setOpen(true); // פתיחת הדיאלוג לאחר קבלת הנתונים
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };
  
  // פונקציה לסגירת הדיאלוג
  const handleClose = () => setOpen(false);
  

  const columns = [
    {
      field: "number",
      headerName: "מספר כרטיס",
      minWidth: 120,
      valueGetter: (params) => params.row.number || params.row.combineCardIdentifier || "",
    },    
    {
      field: "issueDate",
      headerName: "תאריך הנפקה",
      minWidth: 80,
      valueGetter: ({ value }) =>
        value ? new Date(value).toLocaleDateString("he-il") : "",
    },
    {
      field: "user",
      headerName: "שייך למשתמש",
      minWidth: 180,
      valueGetter: ({ value }) =>
        value ? `${value.firstName || ""} ${value.lastName || ""}`.trim() : ""

    },
    {
      field: "cardType",
      headerName: "סוג הכרטיס",
      minWidth: 100,
      valueGetter: ({ row }) => `${row.cardType || "prepay"} ${row.activityStatus || ""}`,
    },   
    {
      field: "organization",
      headerName: "שייך לארגון",
      minWidth: 180,
      valueGetter: (params) => params.row.CustomerName || params.row.organization || "",
    },
    {
      field: "balance",
      headerName: "יתרה",
      minWidth: 100,
      renderCell: ({ row }) =>
        row.cardType === "upay" ? (
          balanceStates[row._id] !== undefined ? (
            <Typography
              style={{ color: balanceStates[row._id]?.isError ? "red" : "black" }}
            >
              {balanceStates[row._id]?.balance}
            </Typography>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => getBalance(row)}
            >
              ברר יתרה
            </Button>
          )
        ) : (
          <Typography style={{ color: "black" }}>{row.balance}</Typography>
        ),
    },
    {
      field: "transactions",
      headerName: "עסקאות בכרטיס",
      minWidth: 120,
      renderCell: ({ row }) =>
        row.cardType === "upay" ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => getTransactions(row)}
            >
              עסקאות בכרטיס
            </Button>  
        ) : "",
    },
    {
      field: "actions",
      type: "actions",
      width: 50 * 2,
      cellClassName: "actions",
      getActions: ({ row }) => [
        <IconButton
          aria-label="add"
          onClick={() => {
            setSelectedItem(row);
            if (!row.user) openConnectUserToCardDialog();
            else deleteCardFromUserHandler(row.user, row);
          }}
          key={`add-btn-${row._id}`}
          color={row.user ? "error" : ""}
        >
          {row.user ? <PersonRemoveIcon /> : <PersonAddIcon />}
        </IconButton>,
        <IconButton
          aria-label="delete"
          onClick={() => {
            deleteItemHandler(row);
          }}
          key={`delete-btn-${row._id}`}
        >
          <DeleteIcon />
        </IconButton>,
      ],
    },
  ];

  const onSuccess = (message = "השינויים נשמרו") => {
    enqueueSnackbar(message);
    mutate();
  };
  const onError = (err) => {
    enqueueSnackbar(err.message || "אירעה שגיאה", { variant: "error" });
  };

  async function deleteCardFromUserHandler(user, card) {
    try {
      await confirm({
        description: `לנתק את כרטיס מספר ${card.number} מ-'${user.firstName} ${user.lastName}'?`,
        confirmationText: "נתק",
      });
      const res = await appFetch(
        `/api/card/${card._id}/detach-from-user/${user._id}`,
        {
          method: "delete",
        }
      );
      if (!res.ok) throw new Error();
      onSuccess();
    } catch (err) {
      onError(err);
    }
  }

  async function deleteItemHandler(item) {
    try {
      await confirm({ description: `למחוק את הכרטיס '${item?.number}'?` });
      const res = await appFetch(`${baseUrl}/${item._id}`, {
        method: "DELETE",
      });
      if (!res.ok) throw new Error();
      onSuccess();
    } catch (err) {
      onError(err);
    }
  }

  async function onCellEditCommit({ id, field, value }) {
    try {
      const res = await appFetch(`${baseUrl}/${id}`, {
        method: "PUT",
        body: JSON.stringify({ [field]: value }),
      });
      if (!res.ok) throw new Error();
      onSuccess();
    } catch (err) {
      onError(err);
    }
  }

  const addItemHandler = () => {
    openCreateCardDialog();
  };
  return (
  <Box sx={{ height: "80vh", maxWidth: "100%", overflow: "auto" }}>
    <DataGrid
      rows={data || []}
      columns={columns}
      onCellEditCommit={onCellEditCommit}
      getRowId={(row) => row._id}
      initialState={{
        sorting: {
          sortModel: [{ field: "createdAt", sort: "desc" }],
        },
      }}
      loading={!data}
    />
    <CreateCardDialog
      onClose={closeCreateCardDialog}
      open={isOpenCreateCardDialog}
      onSuccess={() => mutate()}
    />
    <ConnectCardToUserDialog
      card={selectedItem}
      open={isOpenConnectUserToCardDialog}
      onClose={closeConnectUserToCardDialog}
      onSuccess={() => mutate()}
    />
       <AppFAB label="פעולות" onClick={openActionDialog} />

       <Dialog open={isOpenActionDialog} onClose={closeActionDialog}>
  <DialogTitle>בחר פעולה</DialogTitle>
  <DialogActions>
    <Box display="flex" flexDirection="column" width="100%" gap={1}>
      <Button onClick={() => { closeActionDialog(); addItemHandler(); }} color="primary">
        הוסף כרטיס
      </Button>
      <Button onClick={() => { closeActionDialog(); upayCardsFromExcel(upayData); }} color="primary" disabled={loading}>
        עדכון כרטיסים מ-UPay ל-MongoDB ועדכן סטטוס בMongoDB על פי נתוני אקסל
      </Button>
      <Button onClick={() => { closeActionDialog(); upayCardsFromZoho(upayData); }} color="primary" disabled={loading}>
        עדכון כרטיסים מ-UPay ל-MongoDB ועדכן סטטוס בMongoDB על פי נתוני זוהו
      </Button>
      <Button
          onClick={async () => {
            closeActionDialog();
            if (!data) return; // בדיקה שהנתונים קיימים

            // קבלת כל השורות הרלוונטיות
            const upayRows = data.filter(row => row.cardType === "upay");

            for (const row of upayRows) {
              await getBalance(row);
              await new Promise(resolve => setTimeout(resolve, 1000)); // השהייה של שנייה
            }
          }}
          color="primary"
          disabled={loading || !data}
        >
          עדכן ב-MongoDB את יתרת כל הכרטיסים
        </Button>
      <Button onClick={() => { closeActionDialog(); getAllCardsLoads(); }} color="primary" disabled={loading}>
        משיכת כל הטענות הכרטיסים
      </Button>
    </Box>
  </DialogActions>
</Dialog>


      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>רשימת עסקאות</DialogTitle>
      <DialogContent>
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={flatTransactionsStates.undefined}
            columns={transactionsColumns}
            getRowId={(row) =>  row.id} 
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
          />
        </div>
      </DialogContent>
    </Dialog>

    {loading && <LinearProgress />} {/* תצוגת טוען בזמן בקשה */}
  </Box>
);

};

export default CardsPage;
